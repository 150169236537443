@use '../_settings' as set;

.geTo_titleSection {
	margin: 100px 23% 0;
	@media screen and (max-width: set.$tablet) {
		margin: 70px 14% 0;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 45px 4.8% 0;
	};
	text-align: center;

	@media screen and (max-width: 850px) {
		@include set.gridToFlexCol();
		margin-bottom: 65px;
	}

	.geTo_title {

		h2 {
			@include set.h1();

			@media screen and (max-width: 850px) {
				margin-bottom: 50px;
			}
		}
	}

	.geTo_subtitle {

		div {
			@include set.centered();
			margin: 105px 0 120px;

			@media screen and (max-width: 1024px) {
				margin: 100px 0 75px;
			}
			@media screen and (max-width: 850px) {
				display: block;
				margin: 0;
			}

			img {
				margin-right: 16px;

				@media screen and (max-width: 850px) {
					transform: rotate(90deg);
					margin: 30px auto 24px;
				}
			}

			h4 {
				@include set.h4();
				color: set.$Blue;
				/* Because of font family */
				margin-top: -6px;
			}
		}
	}
}

.geTo_formSection {
	margin: 0 23% 90px;
	@media screen and (max-width: set.$tablet) {
		margin: 0 14% 90px;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 4.8% 60px;
	};

	/* Loading indicator text */
	.isSubmitting {
		display: none;
	}

	form {

		input, textarea {
			@include set.body();

			@media screen and (max-width: set.$mobile) {
				min-height: 48px;
			}
		}

		/* Red point */
		.form-control-label:before {
			width: 6px !important;
			height: 6px !important;
			margin-top: 4px;
		}

		/* check marks validIcon */
		.validIcon svg {
			display: none;
		}

		/* Validation messages */
		.errorMessage span {
			font-size: 1rem;
		}

		.field-text, .field-phone, .field-email, .field-textarea {

			label {
				@include set.h4();
				color: set.$Grey;
				text-transform: none;
				letter-spacing: 0;
				line-height: 30px !important;

				@media screen and (max-width: set.$mobile) {
					font-size: 20px;
					line-height: 24px;
				}

				svg {
					display: none;
				}
			}

			.input-wrapper > div {
				border: unset;
				border-radius: unset !important;
				border-bottom: 1px solid set.$Black;
			}
		}

		.field-phone {

			.input-wrapper {
				padding: 0;
			}

			input {
				&::after {
					background-color: set.$Yellow_Btn1;
				}
			}

			/* flag */
			.phone-flag {
				@include set.centered();
			}

			/* country and phone number box */
			.phone-country, .phone-number {
				width: initial;

				&:after {
					background-color: set.$Yellow_Btn1;
				}

				input {
					border-bottom: none;
				}
			}

			.phone-country {
				max-width: 9ch;

				&::before {
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.phone-number {
				max-width: 20ch;
			}
		}

		.field-textarea {
			textarea {
				margin-top: 9px;
				min-height: 35px;
			}
		}

		.field-checkbox {
			@include set.body();
			margin-top: 60px;

			@media screen and (max-width: set.$mobile) {
				margin-top: 25px;
			}

			.form-control-label {
				display: none;
			}

			/* checkbox and label */
			.input-wrapper > div div {
				margin: 0 auto;

				label {
					/* checkbox */
					.checkbox {
						align-items: center;

						.checkbox-inner {
							min-width: 24px;
							width: 24px;
							height: 24px;
							border: 2px solid #045BAC;
							border-radius: 4px;
							background-color: unset;
							--checkbox-color-active: none;

							.checkbox-mark-active {
								border-color: set.$Yellow_Btn1;
								border-width: 3px;
								top: 50%;
								left: 50%;
								transform: translate(-125%, 25%) scaleX(-1) rotate(135deg);
							}

							svg {
								width: 24px;
								height: 24px;
								stroke: set.$Yellow_Btn1;
								stroke-width: 1.65px;
							}
						}

						/* label */
						.checkbox-label {
							margin-bottom: 6px; /* because of the font family */
						}
					}
				}
			}

			/* checkbox field validation message */
			.errorMessage span {
				@include set.centered();
				padding-bottom: 16px;
			}
		}

		.sendBtn {
			@include set.centered();
			@include set.buttonPrimary();

			margin: 0 auto;
			padding: 14px 42px;
			cursor: pointer;
			border-width: 0;
		}
	}
}

.geTo_mapSection {
	margin: 0 16%;
	@media screen and (max-width: set.$tablet) {
		margin: 0 8%;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 4.8%;
	};

 .textContainer {
	 display: flex;
	 flex-wrap: wrap;
	 justify-content: space-between;

	 @media screen and (max-width: 850px) {
		 flex-direction: column;
	 }

	 .textCard {
		 width: calc(23.25vw - 35px);
		 max-height: 350px;
		 height: calc(350px - (100vw - 850px) * 0.1);
		 min-height: 275px;
		 padding-bottom: 15px;
		 display: flex;
		 flex-direction: column;
		 justify-content: space-between;
		 box-sizing: border-box;
		 box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
		 border: 1px solid transparent;
		 transition: 0.4s;

		 &:hover {
			 border-color: set.$Lightblue;
			 cursor: pointer;
		 }

		 @media screen and (max-width: set.$tablet) {
			 width: calc(28vw - 30px);
		 }
		 @media screen and (max-width: 850px) {
			 width: 80%;
			 margin: 0 auto;
			 max-height: 290px;
		 }

		 h4 {
			 @include set.h4();
			 color: set.$Lightblue;
			 text-align: center;
			 padding: 20px 0 40px;
		 }

		 .textRow {
			 display: flex;
			 align-items: center;
			 margin-bottom: 20px;
			 margin-left: 5%;

			 @media screen and (max-width: 1200px) {
				 margin-left: 0;
			 }
			 @media screen and (max-width: 850px) {
				 margin-left: 14%;
			 }
			 @media screen and (max-width: set.$mobile) {
				 margin-left: 7%;
			 }

			 img {
				 margin: -10px 0;
			 }

			 p {
				 @include set.body();
				 color: set.$Black;
				 margin-top: -6px;
				 margin-right: 10%;

				 @media screen and (max-width: 850px) {
					 margin-left: 2%;
				 }
				 @media screen and (max-width: set.$mobile) {
					 margin-left: 0;
				 }
			 }
		 }
	 }

	 .selectionMarkerContainer {
		 height: 80px;
		 @include set.centered();
	 }
 }

	.mapContainer {
		margin-bottom: 80px;
	}
}
