@use '../_settings' as set;

.portfolio-pureco > .content {

	.portfolio1 {
		margin: 150px 20% 0 20%;

		@media screen and (max-width: set.$large) {
			margin: 150px 25px 0 25px;
		}

		@media screen and (max-width: set.$tablet) {
			display: block !important;
			margin: 100px 15px 0 15px;
		}

		h3 {
			@include set.h2;
			margin: 20px 0;
		}

		h4 {
			@include set.h4;
			margin: 20px 0;
		}

		p {
			@include set.body;
		}

		.project {
			margin: 350px 0 0 50px;

			@media screen and (max-width: 1450px) {
				margin: 250px 0 0 50px;
			}

			@media screen and (max-width: set.$tablet) {
				margin: 0;
			}
		}

		.projectImg {
			text-align: center;

			img {
				max-width: 505px;
				@media screen and (max-width: 1450px) {
					width: 350px;
				}

				@media screen and (max-width: set.$tablet) {
					width: 100%;
				}
			}
		}

	}

	.portfolio2 {
		padding: 0 20% 30px 20%;
		background: linear-gradient(180deg, rgba(44, 186, 231, 0.05) 50.49%, rgba(4, 91, 172, 0.06) 100%);

		@media screen and (max-width: set.$large) {
			padding: 0 25px 30px 25px;
		}

		@media screen and (max-width: 900px) {
			display: flex !important;
			flex-direction: column;

			> section > div {
				display: flex;
				justify-content: center;

				> div {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}

		.technology {
			@include set.h2;
			margin-top: 40px;
		}

		h4 {
			@include set.h4;
			margin: 20px 0;
		}

		p {
			@include set.caption();
			text-align: center;
			background-color: set.$Blue;
			padding: 8px;
			margin: 5px 5px 5px 0;
			color: set.$Grey_Btn2Hover;
			width: fit-content;
			float: left;
		}
	}

	.portfolio3 {
		padding: 0 20%;

		@media screen and (max-width: set.$large) {
			padding: 25px;

			picture {
				> img {
					margin-left: 25px;
				}
			}
		}

		@media screen and (max-width: set.$tablet) {
			display: block !important;
			padding: 0 10px;

			picture {
				> img {
					display: none;
				}
			}
		}

		h2 {
			@include set.h2;
			margin: 50px 0 20px 0;
		}

		h3 {
			@include set.h2;
			color: set.$Lightblue;
			margin: 20px 0;
		}

		p {
			@include set.body;
			margin: 20px 0 50px 0;
		}
	}

	.portfolio4 img {
		width: 100vw;
		max-width: 100%;
	}

	.portfolio5 {
		margin: 50px 20%;

		@media screen and (max-width: set.$large) {
			margin: 25px;
		}

		@media screen and (max-width: set.$tablet) {
			display: block !important;
			margin: 15px 15px;
		}

		.purecoMobileImg {
			display: flex;
			align-items: center;

			@media screen and (max-width: set.$large) {
				justify-content: flex-end;
			}

			@media screen and (max-width: set.$tablet) {
				justify-content: center;
				img {
					max-width: 100%;
				}
			}
		}

		h4 {
			@include set.h3;
			color: set.$Lightblue;
			margin: 20px 0 20px 0;
		}

		p {
			@include set.body;
			margin: 20px 0 50px 0;
		}

		img {
			margin-right: 40px;

			@media screen and (max-width: set.$tablet) {
				margin: 0;
			}
		}
	}
}
