@use '../_settings' as set;

.links {
	margin-top: 50px;

	@media screen and (max-width: set.$mobile) {
		display: flex !important;
		flex-direction: column;
		align-items: center;
		margin: 50px 5%;

		.footerSrgLogo img {
			@media screen and (max-width: set.$mobile) {
				margin-bottom: 20px;
			}
		}
	}

	@media screen and (min-width: set.$tablet) {
		margin: 120px 20% 0;
	}

	.footerLink {
		margin: 0 5px 35px 5px;

		.cHeader {
			border: none;
			display: block;

			button {
				margin: auto;
				background: none;
				@media screen and (min-width: (set.$mobile + 1px)) {
					display: none;
				}
			}

			.cTitle {
				display: block;
			}
		}

		@media screen and (max-width: set.$mobile) {
			text-align: center;
		}

		p {
			@include set.body();
			color: set.$Blue;

			a {
				color: set.$Blue;
				text-decoration: none;
			}
		}

		h4 {
			@include set.caption();
			margin-bottom: 10px;
			color: set.$Lightblue;
		}
	}

	/* Newsletter */
	.isSubmitting {
		display: none;
	}

	form {
		background-color: set.$Grey_Btn2Hover;

		@media screen and (max-width: set.$mobile) {
			width: 90.4vw;
			margin-left: 0;
		}

		.formFields_wo_group {
			display: flex;
			justify-content: space-evenly;

			/* Red point */
			.form-control-label:before {
				width: 6px !important;
				height: 6px !important;
				margin-top: 2px;
			}

			.field-email {
				width: 55%;
				padding-bottom: 15px;

				label {
					@include set.body();
					color: set.$Blue;
					padding-top: 7px;
					padding-bottom: 7px;
					text-transform: none;

					/* Check mark */
					span > svg {
						display: none;
					}
				}

				> div > div {
					@media screen and (min-width: set.$mobile + 1px) {
						height: 32px;
						min-height: 32px;
					}
				}
			}

			.subscribeBtn {
				width: 29%;
				height: 30px;
				margin: 41px 0 16px;
				background-color: set.$Yellow_Btn1;
				border-width: 0;
				border-radius: 2px;
				cursor: pointer;

				@media screen and (max-width: set.$mobile) {
					height: 40px;
				}
				@media screen and (min-width: set.$mobile) and (max-width: set.$medium) {
					width: 36%;
				}
				@media screen and (min-width: set.$tablet) and (max-width: set.$contentWidth) {
					width: 35%;
				}
			}
		}
	}
}

.contactUs {
	@include set.pageMargin();
	background: linear-gradient(237.39deg, set.$Yellow_Btn1Hover 34.29%, set.$Yellow_Btn1 85.55%);
	border-radius: 30px;
	padding: 30px;

	@media screen and (max-width: set.$mobile) {
		margin: 0;
		border-radius: 0;
	};

	img {
		width: 52px;
		height: 52px;
	}

	h3 {
		@include set.h3();
		margin: 20px 0 40px 0;
		text-align: center;
	}

	h4 {
		@include set.textBase();
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.02em;
		margin-top: 30px;
	}

	p {
		margin-top: 20px;
	}

	// Arrows and button
	p:nth-of-type(2) {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 19px;
		}
	}

	a {
		@include set.buttonSecondaryYellow();
		margin: 0 23px;
		padding: 8px 32px 9px;
		border: 1px solid set.$Lightblue;
	}
}

// Contact us is not displayed in sample blog-article-x pages
.blog-article-a .contactUs, .blog-article-b .contactUs, .blog-article-c .contactUs,
.blog-article-d .contactUs, .blog-article-e .contactUs, .blog-article-f .contactUs,
.blog-article-g .contactUs, .blog-article-h .contactUs, .blog-article-i .contactUs,
.blog-article-j .contactUs, .blog-article-k .contactUs, .blog-article-l .contactUs {
	display: none;
}

.greyBackgroundSection {
	background: set.$GreyBackground2;
	padding: 30px 0;

	.feedback {
		margin-bottom: 100px;

		h3 {
			@include set.h2();
			text-align: center;
		}

		h4 {
			@include set.h4();
			color: set.$Grey;
		}

		p {
			@include set.caption();
			color: set.$Blue;
			margin: 20px 0;
		}
	}
}
