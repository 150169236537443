@use '../_settings' as set;

.ab_about {
	@include set.pageMargin();
	margin-bottom: 50px;

	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
	}

	.ab_aboutTitle h2 {
		@include set.h1();
		margin: 105px auto 90px;
		text-align: center;

		@media screen and (max-width: set.$tablet) {
			margin: 80px auto 85px;
		}
	}

	.ab_aboutText p {
		@include set.body();
		max-width: 505px;
		margin: 0 5% 32px 0;

		@media screen and (max-width: 768px) {
			margin-right: 0;
		}
	}

	.ab_srgIllustration {
		@include set.srgIllustration();

		min-height: 400px;
		margin-left: 5%;

		@media screen and (max-width: 768px) {
			margin-left: 0;
			margin-top: 40px;
		}
		@media screen and (max-width: set.$mobile) {
			display: none;
		}
	}
}

.ab_inNumbers {
	background: set.$GreyBackground3;
	padding: 0 14% 80px;

	@media screen and (max-width: set.$tablet) {
		padding: 0 8% 25px;
	};
	@media screen and (max-width: set.$mobile) {
		padding: 0 4.8% 25px;
	};

	.ab_inNumbersTitle h2 {
		@include set.h2();
		color: set.$Blue;
		text-align: center;
		margin: 55px auto 80px;

		@media screen and (max-width: 768px) {
			margin: 50px auto 75px;
		}
	}

	.ab_animatedNumberContainer, .ab_numberTexts div {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-around;
		width: 100%;

		@media screen and (max-width: 850px) {
			justify-content: space-between;
		}
	}

	.ab_animatedNumberContainer {
		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.ab_numberTexts div {
		flex-wrap: nowrap;
		margin-top: 50px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			margin-top: 0;
		}

		strong, i {
			@include set.h3();
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		strong {
			color: set.$Black;
		}

		i {
			color: set.$Lightblue;

			@media screen and (max-width: 768px) {
				margin-bottom: 75px;
			}
		}
	}

	.ab_animatedNumber {
		position: relative;

		/* number */
		strong {
			position: absolute;
			top: 42%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: set.$Blue;
			font-weight: 700;
			font-size: 80px;
			line-height: 131px;
		}
	}
}

.ab_thePeople {
	@include set.pageMargin();
	margin: 0 max(14%, calc((100% - 1040px) / 2));

	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
		align-items: flex-end !important;
	}

	.ab_thePeopleTitle h2 {
		@include set.h2();
		text-align: center;
		margin: 116px auto 144px;

		@media screen and (max-width: set.$tablet) {
			margin: 80px auto 144px;
		}
		@media screen and (max-width: 768px) {
			margin: 75px auto;
		}
		@media screen and (max-width: set.$mobile) {
			margin: 60px auto;
		}
	}

	.ab_thePeopleText {
		h3 {
			@include set.h3();
			margin-right: 10%;

			@media screen and (max-width: set.$tablet) {
				margin-right: 0;
			}
		}

		h3:nth-of-type(2) {
			color: set.$Lightblue;
			margin-top: 40px;

			@media screen and (max-width: set.$tablet) {
				margin-top: 35px;
			}
			@media screen and (max-width: 768px) {
				margin: 40px auto 50px;
			}
		}
	}

	.ab_thePeopleImg1Section {
		justify-self: end;
	}

	.ab_thePeopleImg3Section {
		align-self: flex-start;
	}

	.ab_thePeopleImg_1 img, .ab_thePeopleImg_3 img {
		width: 30vw;
		min-height: 340px;
		object-fit: cover;
		object-position: left;

		@media screen and (max-width: 768px) {
			display: flex;
			width: 85%;
			height: auto;
			min-height: 150px;
			object-fit: contain;
		}
		@media screen and (max-width: set.$mobile) {
			width: 70%;
		}
	}

	.ab_thePeopleImg_1 img {
		@media screen and (max-width: 768px) {
			justify-content: flex-end;
			margin: 0 0 0 auto;
		}
	}

	.ab_thePeopleImg_2 img {
		width: 100%;
		height: 438px;
		margin: 50px auto;
		object-fit: cover;
		object-position: left;

		@media screen and (max-width: 768px) {
			height: 375px;
			margin: 25px auto;
			object-position: 10%;
		}
		@media screen and (max-width: set.$mobile) {
			height: 300px;
			margin: 14px auto;
			object-position: 75%;
		}
	}

	.ab_thePeopleBlueBoxSection {
		@media screen and (max-width: 768px) {
			align-self: center;
		}

		.ab_thePeopleBlueBox {
			margin: 0 10%;
			padding: 32px;
			background: set.$Lightblue;
			border-radius: 30px;

			@media screen and (max-width: 768px) {
				margin: 40px auto;
			}

			h4 {
				@include set.h4();
				color: white;
				margin-bottom: 18px;
			}

			a {
				color: set.$Grey_Btn2Hover;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				text-decoration: underline;
			}

			p {
				color: set.$Grey_Btn2Hover;
				font-size: 28px;
			}
		}
	}
}

/* Hover image content type */
.ab_theTeam {
	@include set.pageMargin();
	margin-top: 40px;
	margin-bottom: 40px;

	@media screen and (max-width: 1350px) {
		margin-top: 55px;
	}
	@media screen and (max-width: 768px) {
		margin-top: 35px;
	}

	.ab_theTeamSection {
		h2 {
			@include set.h2();
			color: set.$Lightblue;
			position: absolute;
			top: 30px;
			z-index: 1;
			//margin-top: 30px;

			@media screen and (max-width: 1350px) {
				top: -15px;
			}
			@media screen and (max-width: 768px) {
				position: static;
				//top: 0;
				@include set.textBase();
				font-weight: 700;
				font-size: 40px;
				line-height: 50px;
				text-align: center;
				//margin-bottom: -10px;
			}
		}

		.imageAndCircles {
			position: relative;
			height: 60vw;
			left: 50%;
			transform: translateX(-50%);
			max-height: 979px;
			max-width: 1175px;

			@media screen and (max-width: set.$tablet) {
				height: 70vw;
			}
			@media screen and (max-width: 768px) {
				height: 70vw;
				margin-bottom: 50px;
			}
			@media screen and (max-width: set.$mobile) {
				height: 75vw;
				margin-bottom: 40px;
			}

			.teamImg {
				position: absolute;
				top: 5%;
				left: 50%;
				transform: translateX(-50%);
				width: 72vw;
				max-width: 1175px;
				height: auto;

				@media screen and (max-width: set.$tablet) {
					width: 84vw;
				}
				@media screen and (max-width: 768px) {
					height: 70vw;
					object-fit: cover;
					top: 0;
				}
				@media screen and (max-width: set.$mobile) {
					width: 90.4vw;
					height: 75vw;
				}
			}

			.outerCircle {
				position: absolute;
				width: 83.14%;
				max-width: 995px;
				aspect-ratio: 1;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				opacity: 0.3;
			}

			.innerCircleContainer {
				position: absolute;
				top: 0;
				left: max(8.43%, calc((100% - 995px) / 2));
				width: 83.14%;
				max-width: 995px;
				aspect-ratio: 1;
			}

			.innerCircleContainer_1, .innerCircleContainer_2, .innerCircleContainer_3,
			.innerCircleContainer_4, .innerCircleContainer_5, .innerCircleContainer_6 {
				position: absolute;
				width: 11.67%;
				aspect-ratio: 1;
			}

			/* Position of team member 1 */
			.innerCircleContainer_1 {
			}

			/* Position of team member 2 */
			.innerCircleContainer_2 {
				top: 25.4%;
				left: 43.3%;
			}

			/* Position of team member 3 */
			.innerCircleContainer_3 {
				top: 14.5%;
				left: 65.65%;
			}

			/* Position of team member 4 */
			.innerCircleContainer_4 {
				top: 16%;
				left: 25.65%;
			}

			/* Position of team member 5 */
			.innerCircleContainer_5 {
				top: 16.3%;
				left: 33.8%;
			}

			/* Position of team member 6 */
			.innerCircleContainer_6 {
				top: 24.5%;
				left: 54.85%;
			}

			.innerCircle {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -6.75%;
				width: 14%;
				max-width: 139px;
				opacity: 0.6;
			}

			.innerCircleContainer, .innerCircleContainer_1, .innerCircleContainer_2, .innerCircleContainer_3,
			.innerCircleContainer_4, .innerCircleContainer_5, .innerCircleContainer_6, .innerCircle, .outerCircle {
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}

		.flexContainer {
			position: absolute;
			width: 100%;
			margin-top: -12.5%;
			display: flex;
			justify-content: space-between;
			z-index: 1;

			@media screen and (max-width: 768px) {
				display: none;
			}

			.element {
				cursor: pointer;

				h3 {
					@include set.h3();
					color: set.$Blue;

					&:hover {
						color: set.$Yellow_Btn1;
					}
				}

				p {
					@include set.body();
					color: set.$Lightblue;
				}
			}
		}
	}
}

.ab_leaderSection {
	margin: 50px 14% 0;

	@media screen and (max-width: set.$tablet) {
		margin: 60px 8% 0;
	};
	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
		margin: 50px 8% 0;
	}
	@media screen and (max-width: set.$mobile) {
		margin: 40px 4.8% 0;
	};

	/* desktop */
	.ab_leaderImgSection_even {
		justify-self: end !important;
	}

	section {
		margin-bottom: 60px;

		@media screen and (max-width: 768px) {
			margin-bottom: 0;
		}
	}

	/* tablet */
	.ab_leaderImgSection_odd, .ab_leaderTextSection_odd {
		@media screen and (max-width: 768px) {
			align-self: flex-start;
		}
	}

	.ab_leaderImgSection_even, .ab_leaderTextSection_even {
		@media screen and (max-width: 768px) {
			align-self: flex-end;
		}
	}

	.ab_leaderTextSection_odd, .ab_leaderTextSection_even {
		@media screen and (max-width: 768px) {
			margin-bottom: 75px;
		}
	}


	/* mobile */
	.ab_leaderImgSection_odd, .ab_leaderImgSection_even {
		@media screen and (max-width: set.$mobile) {
			align-self: center;
			margin-bottom: 12px;
		}
	}

	.ab_leaderTextSection_odd, .ab_leaderTextSection_even {
		@media screen and (max-width: set.$mobile) {
			align-self: flex-start;
			margin-bottom: 75px;
		}
	}

	.ab_leaderImg {
		display: block;
		width: 245px;
	}

	.ab_leaderText {
		width: 23vw;
		margin-left: 24px;

		@media screen and (max-width: 1440px) {
			width: 322px;
		}
		@media screen and (max-width: 768px) {
			width: 400px;
			margin-left: 0;
		}
		@media screen and (max-width: set.$mobile) {
			width: auto;
		}

		h3 {
			@include set.h3();
			color: set.$Blue;
		}

		h4 {
			@include set.h4();
			color: set.$Lightblue;
			margin: 2px auto 20px;
		}

		p {
			@include set.textLink();
		}
	}
}

.ab_whatDrives {
	margin: 125px 14% 140px;

	@media screen and (max-width: set.$tablet) {
		margin: 40px 8% 115px;
	};
	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
		margin: 30px 8% 105px;
	}
	@media screen and (max-width: set.$mobile) {
		margin: 5px 4.8% 50px;
	};

	.ab_whatDrivesTitle h2 {
		@include set.h2();
		text-align: center;
	}

	.ab_whatDrivesImg img {
		margin: 75px auto;

		@media screen and (max-width: 768px) {
			margin: 60px auto 25px;
		}
	}

	.ab_whatDrivesText p {
		@include set.body();
		margin: auto 7.5% auto;
		text-align: center;
	}
}

/* circle animation above numbers and on team image*/
@keyframes rotating {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
