@use '../_settings' as set;

.bl_titleSection {
	margin: 15px 0;
	background: set.$LightBlueBackground;
	padding: 0 14%;

	@media screen and (max-width: set.$tablet) {
		padding: 0 8%;
	}
	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
	}
	@media screen and (max-width: set.$mobile) {
		padding: 0 4.8%;
	}

	.bl_titleText {
		position: relative;
		z-index: 2;

		/* label */
		strong {
			@include set.caption();
			width: 60px;
			text-align: center;
			background-color: set.$Blue;
			color: set.$Grey_Btn2Hover;
			display: block;
			margin-top: 55px;
			padding: 2px 10px 6px;
			border-radius: 2px;

			@media screen and (max-width: set.$tablet) {
				margin-top: 25px;
			}
		}

		h2 {
			@include set.h1();
			margin: 50px 5% 65px 0;

			@media screen and (max-width: 1400px) {
				width: 570px;
			}
			@media screen and (max-width: set.$tablet) {
				margin: 30px 5% 55px 0;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}

		h4 {
			@include set.h4();

			@media screen and (max-width: 1465px) {
				max-width: 378px;
			}
			@media screen and (max-width: set.$tablet) {
				max-width: 37vw;
			}
			@media screen and (max-width: 768px) {
				max-width: 100%;
			}
		}

		/* button */
		p:nth-of-type(2) {
			margin: 75px auto 215px;

			@media screen and (max-width: 768px) {
				margin: 65px auto 70px;
			}

			a {
				@include set.buttonPrimary();
			}
		}
	}

	.bl_titleImgSection {
		position: relative;

		@media screen and (max-width: 768px) {
			align-self: center;
		}

		.bl_titleImg {

			/* main illustration */
			p:first-of-type img {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);

				@media screen and (max-width: 1400px) {
					width: 26vw;
					min-width: 300px;
					top: min(230px, calc(125px + (1400px - 100vw) / 2));
					transform: translateY(0);
				}

				@media screen and (max-width: 768px) {
					position: static;
					top: auto;
					right: auto;
					width: 100%;
					margin-bottom: 100px;
				}
			}

			p:nth-of-type(2) {
				z-index: 1;

				/* Big and small circle */
				img:nth-of-type(1), img:nth-of-type(2) {
					position: absolute;
				}

				img:nth-of-type(1) {
					top: 0;
					right: -13.75vw;

					@media screen and (max-width: 1400px) {
						top: calc((1400px - 100vw) / 3);
						width: max(49vw, 500px);
					}
					@media screen and (max-width: set.$tablet) {
						top: 140px;
					}
					@media screen and (max-width: 768px) {
						display: none;
					}
				}

				img:nth-of-type(2) {
					top: 822px;
					right: -13.75vw;

					@media screen and (max-width: 1400px) {
						display: none;
					}
				}
			}
		}
	}
}

.bl_filterSection {
	margin: 80px 0;

	@media screen and (max-width: 1200px) {
		margin: 40px 0;
	}
	@media screen and (max-width: set.$tablet) {
		margin: 50px 0;
	}
	@media screen and (max-width: set.$mobile) {
		margin: 20px 0;
	}
}

.bl_blogs {
	margin: 80px 20% 55px;

	@media screen and (max-width: set.$tablet) {
		margin: 80px 8% 75px;
	}
	@media screen and (max-width: set.$mobile) {
		margin: 50px 4.8% 25px;
	}

	ol {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media screen and (max-width: set.$mobile) {
			flex-direction: column;
		}

		li {
			width: 17vw;

			@media screen and (max-width: set.$tablet) {
				width: 25vw;
			}
			@media screen and (max-width: set.$mobile) {
				width: 100%;
			}

			dl {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 80px;

				@media screen and (max-width: set.$mobile) {
					margin-bottom: 55px;
				}

				.images {
					flex: 100%;
					order: 1;

					&:hover {
						opacity: 0.85;
					}

					img {
						width: 17vw;
						height: 17vw;
						object-fit: cover;

						@media screen and (max-width: set.$tablet) {
							width: 25vw;
							height: 25vw;
						}
						@media screen and (max-width: set.$mobile) {
							width: 100%;
							height: 230px;
						}
					}
				}

				.date {
					@include set.body();
					margin: 10px 0 20px;
					flex: 50%;
					order: 2;

					@media screen and (max-width: set.$mobile) {
						margin: 15px 0;
					}
				}

				/* duration, Linkedin, Facebook icons */
				.attributes {
					@include set.body();
					margin: 10px 0 20px;
					flex: 50%;
					order: 3;

					display: flex;
					justify-content: flex-end;

					@media screen and (max-width: set.$mobile) {
						margin: 15px 0;
					}

					.linkedinIcon {
						margin: auto 6px;
					}

					img {
						margin-top: 7px;
					}
				}

				.name {
					@include set.h3();
					flex: 100%;
					order: 4;
				}

				.segments {
					display: none;
				}
			}

			.prevPageButton img, .nextPageButton img {
				width: 14px;
			}
		}

		/* first blog */
		.item-1_on_page-1 {
			width: 100%;

			.images img {
				width: 100%;

				@media screen and (max-width: set.$mobile) {
					width: 100%;
				}
			}
		}

		/* second and third blog */
		.item-2_on_page-1, .item-3_on_page-1,
		.item-2_on_page-1 .images img, .item-3_on_page-1 .images img {
			width: 28vw;

			@media screen and (max-width: set.$tablet) {
				width: 39vw;
			}
			@media screen and (max-width: set.$mobile) {
				width: 100%;
			}
		}

		/* duration and social media icons except first 3 blogs */
		li:not(.item-1_on_page-1, .item-2_on_page-1, .item-3_on_page-1) {
			.date {
				@media screen and (max-width: 1400px) and (min-width: set.$mobile + 1px) {
					flex: 100%;
					margin: 10px 0 0;
				}
			}

			.attributes {
				@media screen and (max-width: 1400px) and (min-width: set.$mobile + 1px) {
					flex: 100%;
					justify-content: flex-start;
				}
			}
		}
	}

	/* pagination container */
	.pagination {
		@include set.centered;

		/* page numbers */
		ul {
			margin: 0 8px;
			padding: 4px;
			user-select: none;

			li {
				@include set.textLink();
				color: set.$Blue;
				margin: 3px 6px 0;
				padding: 6px;

				&:hover {
					font-weight: 700;
				}
			}
		}
	}

	.noResultsText {
		@include set.h4();
		color: set.$Grey;
		margin: 108px auto;
		text-align: center;
	}
}
