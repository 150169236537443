$Black: #1D1D1B;
$Grey: #585C5C;
$GreyBackground: linear-gradient(180deg, #EBEBEB 0%, rgba(245, 245, 245, 0.92) 68.03%);
$GreyBackground2: linear-gradient(180deg, #EBEBEB 0%, rgba(245, 245, 245, 0) 68.03%);
$GreyBackground3: linear-gradient(180deg, rgba(44, 186, 231, 0.04) 50.49%, rgba(4, 91, 172, 0.048) 100%);
$Grey_Btn2Hover: #F2F1F1;
$Blue: #045BAC;
$Lightblue: #2DBBE8;
$LightBlue_2: #89E1FD;
$LightBlue_20: #D5F1FA;
$LightBlueBackground: linear-gradient(180deg, rgba(44, 186, 231, 0.28) 50.49%, rgba(4, 91, 172, 0.048) 100%);
$Yellow_Btn1: #FABE13;
$Yellow_Btn1Hover: #FED768;
$Yellow_Btn2: #FDB400;
$White: #FFFFFF;
