@use '../_settings' as set;

@mixin pageMargin() {
	margin: 0 14%;
	@media screen and (max-width: set.$tablet) {
		margin: 0 8%;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 3.6%;
	};
}

.hoWe_titleSection {
	@include pageMargin();

	.hoWe_title h2 {
		@include set.h1();
		margin: 114px auto 56px;
		@media screen and (max-width: set.$mobile) {
			margin: 60px 4.5% 45px;
		};
	}

	.hoWe_titleText h4 {
		@include set.h4();
		color: set.$Grey;
		max-width: 738px;
		margin: 0 auto 25px;
	}

	.hoWe_titleName p {
		@include set.body();
		color: set.$Grey;
		margin: 0 auto 28px;

		@media screen and (max-width: set.$mobile) {
			margin: 0 auto 70px;
		};
	}

	.hoWe_downArrows img {
		width: 100%;
		height: 41px;
		margin: 0 auto 91px;

		@media screen and (max-width: set.$mobile) {
			display: none;
		};
	}
}

.hoWe_imageSection {
	@include pageMargin();
	grid-row-gap: 3.89vw;

	.hoWe_imageMidUp img, .hoWe_imageMidDown img {
		width: 35vw;
		height: auto;

		@media screen and (max-width: set.$tablet) {
			width: 41vw;
			height: 18.85vw;
			object-fit: cover;
		};
		@media screen and (max-width: set.$mobile) {
			width: 47.3vw;
		};
	}

	.hoWe_imageLeft img, .hoWe_imageRight img {
		width: 14.6vw;
		height: auto;

		@media screen and (max-width: set.$tablet) {
			width: 19.34vw;
			object-fit: cover;
		};
		@media screen and (max-width: set.$mobile) {
			width: 19.15vw;
			height: 37.23vw;
		};
	}

	.hoWe_imageLeft img {
		@media screen and (max-width: set.$mobile) {
			object-position: 80% 20%;
		};
	}
}

.hoWe_ourWorkSection {
	@include pageMargin();

	.hoWe_ourWorkText {
		@include set.h2();
		margin: 190px auto 103px;
		max-width: 687px;

		@media screen and (max-width: set.$tablet) {
			margin: 74px auto 78px;
		};
		@media screen and (max-width: set.$mobile) {
			margin: 51px auto 0;
		};
	}

	.hoWe_sayToUs h4 {
		@include set.h4();
		margin-bottom: 48px;

		@media screen and (max-width: set.$mobile) {
			margin: 36px 0 18px;
			text-align: center;
		};
	}

	.hoWe_downArrows_2 {
		display: none;

		@media screen and (max-width: set.$mobile) {
			display: grid;
			margin: 40px auto;
		};
	}
}

.hoWe_numberedSection {
	@include pageMargin();
	margin-top: 46px;
	margin-bottom: 102px;

	@media screen and (max-width: set.$tablet) {
		margin-bottom: 160px;
	};

	@media screen and (max-width: set.$mobile) {
		display: flex !important;
		flex-direction: column;
		margin-top: 0;
	};

	.hoWe_number_odd img, .hoWe_number_even img {
		width: 154px;
		height: 154px;

		@media screen and (max-width: set.$tablet) {
			width: 140.28px;
			height: 140.28px;
		};
		@media screen and (max-width: set.$mobile) {
			width: 90px;
			height: 90px;
		};
	}

	.hoWe_number_even {
		margin-left: 90px;

		@media screen and (max-width: set.$tablet) {
			margin-left: 80px;
		};
		@media screen and (max-width: set.$mobile) {
			margin-left: 0;
		};
	}

	.hoWe_numberedText_odd, .hoWe_numberedText_even, .hoWe_numberedText_4 {
		margin-left: 70px;
		padding-left: 20px;
		margin-bottom: 124px;

		@media screen and (max-width: set.$tablet) {
			margin-left: 20px;
			margin-bottom: 60px;
		};
		@media screen and (max-width: set.$mobile) {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 40px;
		};

		h3 {
			@include set.h3();
			margin-bottom: 24px;
		}

		p {
			@include set.body();
			color: set.$Grey;
		}
	}

	/* because of too long title of text 4 */
	.hoWe_numberedText_4 {
		h3:nth-of-type(1) {
			@media screen and (max-width: 768px) and (min-width: set.$mobile + 1px) {
				display: none;
			}
		}
		h3:nth-of-type(2) {
			@media screen and (max-width: set.$mobile) {
				display: none;
			}
			@media screen and (min-width: 769px) {
				display: none;
			}
		}
	}

	.hoWe_numberedText_odd {
		margin-left: 0;

		@media screen and (max-width: set.$tablet) {
			margin-left: -60px;
		};
		@media screen and (max-width: set.$mobile) {
			margin-left: 0;
		};
	}
}

.hoWe_blueImageSection {
	position: relative;
	background: linear-gradient(109.67deg, set.$Lightblue 27.31%, set.$Blue 88.14%);
	width: 100%;
	height: 172px;
	margin: 0 auto;
	z-index: -2;

	@media screen and (max-width: set.$mobile) {
		height: 108px;
		z-index: auto;
	};

	img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -10px;
		z-index: -1;

		@media screen and (max-width: set.$mobile) {
			max-width: 270px;
			height: auto;
			bottom: 20px;
			z-index: auto;
		};
	}
}

.hoWe_whyChooseSection {
	background: white;
	margin: 210px 25%;

	@media screen and (max-width: 1230px) {
		margin: 210px 20%;
	}
	@media screen and (max-width: set.$tablet) {
		margin: 80px 8% 160px;
	};
	@media screen and (max-width: 768px) {
		margin: 80px 5% 160px;
		column-gap: 40px !important;
		row-gap: 65px !important;
	}
	@media screen and (max-width: set.$mobile) {
		margin: 140px 3.6% 80px;
		display: flex !important;
		flex-direction: column;
		row-gap: 40px !important;
	};

	.hoWe_whyChoose h2 {
		@include set.h2();
		margin-bottom: -15px;

		@media screen and (max-width: set.$tablet) {
			margin-bottom: -44px;
		};
		@media screen and (max-width: 768px) {
			margin-bottom: 0;
		}
		@media screen and (max-width: set.$mobile) {
			text-align: center;
			margin-bottom: 26px;
		};
	}

	.hoWe_whyChoose_cell {

		img {
			width: 101px;
		}

		h3 {
			@include set.h3();
			margin: 25px auto;
		}

		p {
			@include set.textLink();
		}
	}
}
