@use '../_settings' as set;

.menuSection, .blackMenuSection {

	>section:first-child {
		z-index: 100;

		@media screen and (max-width: 1200px) {
			display: none !important;
		}
	}
}

.blackMenuSection {
	background-color: set.$Black;
	color: set.$White;

	.customLanguageSelector {
		color: set.$Black;

		span {
			color: set.$White !important;

			&:hover {
				color: set.$Yellow_Btn2 !important;
			}
		}
	}

	// hovered Services menu and its submenus in black menu section
	li.megaMenu {

		&:hover {
			border: none !important;
		}

		> ul {
			border: none !important;
			z-index: 101 !important;
		}
	}
}

.blackBreadcrumbSection {
	background-color: set.$Black;

	.breadcrumb .currentLabel {
		color: set.$White !important;
	}
}

.blackHeaderSection {
	background-color: set.$Black;
	color: set.$White;

	.poPu_headerTitle {
		padding: 70px 100px 0 100px;

		@media screen and (max-width: set.$mobile) {
			padding: 100px 15px 0 15px;
		}

		h2 {
			@include set.h1();
			margin: 0;
		}
	}

	.poPu_blueChip {
		margin-top: 60px;

		> div {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
		}

		p {
			@include set.caption();
			text-align: center;
			background-color: set.$Blue;
			padding: 2px 10px 6px;
			margin: 5px;
			color: set.$Grey_Btn2Hover;
			width: fit-content;
			border-radius: 2px;
			float: left;
		}
	}

	.poPu_purecoImg {
		position: relative;
		top: 50px;
		display: flex;
		justify-content: center;
	}

	.poPu_refImg {
		position: relative;
		top: 100px;
		display: flex;
		justify-content: center;

		@media screen and (max-width: 1125px) {
			top: 50px;

			> img {
				width: 100%;
				padding: 20px;
			}
		}
	}
}

.headerImage {
	position: absolute;
	z-index: -1;

	img {
		width: 100vw;
		height: 100vh;
		max-width: 100%;
		object-fit: cover;

		@media screen and (max-width: set.$mobile) {
			height: 650px;
		}
	}
}

.headerMenu {
	height: 100px;
	overflow: hidden;
	margin-left: 14vw;
	@media screen and (max-width: 1350px) {
		margin-left: 8vw;
	};

	ul li:hover {
		> a, > span {
			color: set.$Yellow_Btn1;
		}
	}

	> ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 65vw;

		@media screen and (max-width: 1350px) {
			width: 76vw;
		};
		@media screen and (max-width: 1200px) {
			display: none;
		}

		> li {
			height: 100px;

			> a, > span {
				@include set.caption();
				display: block;
				width: 100%;
				height: 100px;
				line-height: 100px;
				transition: 0.2s;
			}
		}

		a, span {
			margin-left: 0 !important;
		}

		a:link,
		a:visited,
		a:active {
			color: inherit;
		}
	}

	li.megaMenu {
		float: left;
		overflow: hidden;
		border: 2px solid transparent;

		> a, > span {
			padding: 0 23px;
			line-height: 96px;
		}

		&:hover {
			background-color: set.$White;
			border: 2px solid set.$Lightblue;
			border-bottom: 2px solid white;
			border-radius: 3px 3px 0 0;
		}

		.serviceMenu {
			> a, > span {
				text-decoration: none;
				color: set.$Blue;
				@include set.caption();
			}
		}

		> ul {
			display: none;
			grid-template-columns: repeat(4, 1fr);
			position: absolute;
			height: auto;
			width: calc(100vw - 17px);
			top: 98px;
			left: 0;
			margin: 0;
			border-top: 2px solid set.$Lightblue;
			padding: 25px 20%;
			background: set.$White;
			background: linear-gradient(360deg, #E2F6FC -36.89%, #FFFFFF 41.88%);
			backdrop-filter: blur(4px);
			z-index: -1;

			> li {
				margin: 5px;

				a, span {
					@include set.textLink();
					color: set.$Grey;
				}

				> ul {
					margin: 10px 0 0
				}
			}
		}

		&:hover {
			background-color: set.$White;

			> ul {
				display: grid;
			}
		}
	}

	.menuLogo {
		position: relative;
		width: 165px;

		a {
			position: absolute;
			top: 0;
			width: 100%;
			font-size: 0;
		}

		&::before {
			content: url("../assets/srg_colored.png");
			position: relative;
			top: 15px;
		}
	}

	.getInTouch {
		position: relative;
		height: 50px;

		&:hover a,
		&:hover span {
			color: set.$Black;
		}

		a, span {
			@include set.buttonPrimary();
			height: 50px;
			line-height: 25px;
		}
	}
}

.customLanguageSelector {
	margin-right: 14vw;

	@media screen and (max-width: 1350px) {
		margin-right: 8vw;
	};
}

.breadcrumb {
	margin: 0 calc(14% + 10px);
	@media screen and (max-width: 1200px) {
		margin: 0 8%;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 4.8%;
	};

	.elementsContainer {
		display: flex;

		.label {
			@include set.textLink();
			color: set.$Lightblue;

			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.separator {
			margin: 0 10px;
			color: set.$Lightblue;
		}

		.currentLabel {
			@include set.textLink();
			color: set.$Blue;
			user-select: none;
		}
	}
}

.main_titleBtnArrowSection {
	.headerTitle {
		margin: 0 14%;
		padding-top: 275px;
		@media screen and (max-width: 1200px) {
			margin: 0 8%;
		};
		@media screen and (max-width: 870px) and (min-width: set.$mobile + 1px) {
			padding-top: 175px;
		};
		@media screen and (max-width: set.$mobile) {
			margin: 0 4.8%;
			padding-top: 110px;
		};

		h2 {
			@include set.h1();
		}

		h2:first-of-type {
			color: set.$Lightblue;
			margin-bottom: 12px;
		}
	}

	.seeOurServicesBtn {
		margin: 0 14%;
		padding-top: 150px;
		@media screen and (max-width: 1200px) {
			margin: 0 8%;
		};
		@media screen and (max-width: set.$mobile) {
			margin: 0 4.8%;
		};
		@media screen and (max-width: 390px) {
			padding-top: 100px;
		}

		a {
			@include set.buttonPrimary();
		}
	}

	// Blue arrow
	section:nth-of-type(3) {
		position: initial;

		.main_blueDownArrow {
			@include set.centered();
			position: absolute;
			top: calc(100vh - 210px);
			left: 50%;
			transform: translateX(-50%);

			@media screen and (max-width: set.$mobile) {
				display: none;
			};
		}
	}
}

// Different design for dark "theme" pages
.blackMenuSection {
	.menuLogo:before {
		content: url("../assets/srg_white.png");
	}
}

// Header hero image height fix
.main-page .header {
	height: 100vh;

	@media screen and (max-width: set.$mobile) {
		height: 650px;
	}
}

.mobileMenu {
	display: none !important;
	z-index: 100;
	height: 100px;

	.menuButtonContainer {
		display: flex;
		width: 30px;
		height: 30px;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0 20px;

		@media screen and (max-width: 450px) {
			margin: 5px;
		}

		.menuButton {

			&,
			&::before,
			&::after {
				display: block;
				background-color: set.$Black;
				position: absolute;
				height: 4px;
				width: 30px;
				transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
				border-radius: 2px;
			}

			&::before {
				content: '';
				margin-top: -8px;
			}

			&::after {
				content: '';
				margin-top: 8px;
			}

		}
	}

	@media screen and (max-width: 1200px) {
		display: flex !important;
		align-items: center;
		justify-content: flex-end;
	}

	.getInTouch {

		&:hover a,
		&:hover span {
			color: set.$Black;
		}

		a, span {
			@include set.buttonPrimary();
			height: 50px;
			line-height: 25px;

			@media screen and (max-width: 450px) {
				padding: 6.5px 10px 12.5px;
			}
		}
	}

	section:first-child {
		margin-left: 20px;
		margin-right: auto;

		@media screen and (max-width: 450px) {
			margin-left: 0;
		}
	}

	section:nth-child(3) {
		display: flex;
	}

	#menu-toggle {
		display: none;

		&:checked + .menuButtonContainer .menuButton::before {
			margin-top: 0;
			transform: rotate(405deg);
		}

		&:checked + .menuButtonContainer .menuButton {
			background: rgba(255, 255, 255, 0);
		}

		&:checked + .menuButtonContainer .menuButton::after {
			margin-top: 0;
			transform: rotate(-405deg);
		}

		& ~ .mobileHeader ul {
			> li {
				display: none;
			}
		}

		&:checked ~ .mobileHeader {
			position: absolute;
			right: 0;
			margin-top: 50px;
			width: 100vw;

			ul {
				display: flex;
				flex-direction: column;
				width: 100%;
				justify-content: center;
				align-items: flex-end;
				background: linear-gradient(180deg, set.$White 0%, #F3FCFF 100%);
				opacity: 0.95;

				> li {
					display: block;
					margin: 5px 20px;
					padding: 10px 0;
					width: 200px;
					text-align: end;
					border-bottom: 1px solid set.$Lightblue;

					&:last-child {
						border-bottom: 0;
						margin-bottom: 15px;
					}

					> a, > span {
						@include set.h4();
					}

					a:link,
					a:visited,
					a:active {
						color: inherit;
					}

					&:hover {
						> a, > span {
							color: set.$Yellow_Btn1;
						}
					}
				}
			}
		}
	}

	&.black {
		background-color: set.$Black;
		color: set.$White;
		width: 100%;

		>section:last-child {
			order: 1;
			margin-left: 20px;
			margin-right: auto;

			@media screen and (max-width: 450px) {
				margin-left: 0;
			}
		}

		>section:first-child {
			order: 2;
			margin-left: 0;
			margin-right: 0;
		}

		>section:nth-child(2) {
			display: flex;
			order: 3;
		}

		.menuButtonContainer .menuButton {
			background-color: set.$White;

			&:before {
				background-color: set.$White;
			}

			&:after {
				background-color: set.$White;
			}
		}
	}
}
