@use '../_settings' as set;

$contentWidth: 1040px;
$postWidth: 682px;

.content article.blog {
	max-width: $contentWidth;
	margin: 0 auto;
	padding: 40px 15px 0;
	//--
	// Comment in!
	//background: linear-gradient(transparent, 180px, rgb(255, 255, 255), 200px, rgb(51, 186, 228, 0.15), 40%, transparent);
	//--

	>dl {
		display: grid;
		grid-template-areas:
    'media media'
    'date attributes'
    'author author'
    'title title'
    'segments segments'
    'attachments attachments';

		dd.media {
			grid-area: media;

			picture >img {
				width: 100%;
			}
		}

		dd.date {
			grid-area: date;
			@include set.body();
			margin: 5px 0 0 0;
		}

		dd.attributes {
			grid-area: attributes;
			@include set.body();
			margin: 5px 0 0 auto;
			display: flex;
			justify-content: flex-end;

			.linkedinIcon {
				margin: auto 6px;
			}

			img {
				margin-top: 7px;
			}
		}

		dd.author {
			grid-area: author;

			p {
				@include set.h3();
				max-width: $postWidth;
				margin: auto;
				color: set.$Lightblue;
			}
		}

		dt.title {
			grid-area: title;

			h1 {
				@include set.h1();
				max-width: $postWidth;
				margin: 30px auto;
			}
		}

		dd.segments {
			grid-area: segments;
			max-width: $postWidth;
			margin: auto;

			p {
				@include set.body();
				margin: 20px 0;
			}

			strong {
				color: set.$Lightblue;
			}

			img {
				max-width: 100%;
				margin: 20px 0;
			}
		}

		dd.attachments {
			grid-area: attachments;
		}
	}
}
