@use '../settings' as set;

/* Base page margin */
@mixin pageMargin() {
	margin: 0 14%;
	@media screen and (max-width: set.$tablet) {
		margin: 0 8%;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 4.8%;
	};
}

.strategyAndConsultationSection {
	@include pageMargin();
	position: relative;
	z-index: 1;

	.strategyAndConsultationTitle h2 {
		@include set.h1();
		color: black;
		margin: 68px auto 73px;
	}

	.strategyAndConsultationText {
		@include set.body();
		max-width: 597px;
		margin-bottom: 90px;

		@media screen and (max-width: set.$mobile) {
			@include set.h4();
		};
	}

	.stCo_circles_1 {
		position: absolute;
		top: 68px;
		right: -213%;

		@media screen and (max-width: set.$tablet) {
			right: -105%;
		};
		@media screen and (max-width: set.$mobile) {
			right: -55%;
		};
	}
}

.blueBoxSection {
	min-height: 291px;
	padding: 2% 14%;
	margin-bottom: 137px;
	background: set.$LightBlue_20;
	border-radius: 3.6px;

	@media screen and (max-width: set.$tablet) {
		padding: 2% 8%;
		margin-bottom: -15px;
	};
	@media screen and (max-width: set.$mobile) {
		display: flex !important;
		flex-direction: column;
		margin-bottom: 5px;
		padding: 30px 4.8%;
	};

	.blueBoxText li {
		@include set.textBase();
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		max-width: 294px;
		margin: min(8%, 30px) 0 min(8%, 30px) 20%;
		opacity: 1;

		@media screen and (max-width: set.$mobile) {
			margin: 20px 0 20px 10%;
			max-width: 500px;
		};

		strong {
			color: set.$Lightblue
		}
	}
}

.stCo_textsAndImgSection_1, .stCo_textsAndImgSection_2, .stCo_textsAndImgSection_3 {
	@include pageMargin();

	@media screen and (max-width: 1200px) {
		grid-template-columns: auto auto !important;
	};
	@media screen and (max-width: set.$mobile) {
		display: flex !important;
		flex-direction: column;
	};

	.stCo_circles_2 {
		height: 100px;

		@media screen and (max-width: set.$tablet) {
			display: none;
		};
		@media screen and (max-width: set.$mobile) {
			display: flex;
			position: relative;
		};

		img {
			height: 72px;

			@media screen and (max-width: set.$mobile) {
				position: absolute;
				top: -42px;
				right: 0;
			};
		}
	}

	.stCo_img img, .stCo_img_mid img {
		width: 30vw;
		max-width: 838px;
		object-fit: cover;
		height: 470px;

		@media screen and (max-width: set.$mobile) {
			width: 100%;
			height: 243px;
			margin-bottom: 65px;
		};
	}

	.stCo_img img {
		@media screen and (max-width: 1200px) {
			margin-right: 20px;
		};
		@media screen and (max-width: set.$mobile) {
			margin-right: 0;
		};
	}

	.stCo_img_mid img {
		@media screen and (max-width: 1200px) {
			margin-left: 20px;
		};
		@media screen and (max-width: set.$mobile) {
			margin-left: 0;
		};
	}

	.stCo_text, .stCo_text_last {
		margin-bottom: 32px;

		h4 {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-bottom: 14px;

			img {
				width: 34px;
				height: 34px;
				margin-right: 12px;
			}

			strong {
				@include set.h4();
				// because of font-family
				padding-bottom: 6px;
			}
		}

		p {
			@include set.body();
		}
	}

	.stCo_text_last {
		margin-bottom: 0;
	}

	.stCo_downArrows_1, .stCo_downArrows_2 {
		height: 242px;

		@media screen and (max-width: set.$tablet) {
			height: 133px;
		};
		@media screen and (max-width: set.$mobile) {
			height: 80px;
		};

		img {
			height: 40px;

			@media screen and (max-width: set.$tablet) {
				display: none;
			};
		}
	}

	.stCo_downArrows_1 {
		@include set.centered();
	}

	.stCo_downArrows_2 {
		@include set.centeredEnd();
		margin-right: -13.17px;
	}

	.stCo_circles_3 {
		position: absolute;
		top: 30px;
		right: 0;

		img {
			@media screen and (max-width: set.$tablet) {
				display: none;
			};
		}
	}

	.stCo_circles_4 {
		@include set.centeredStart();

		@media screen and (max-width: set.$mobile) {
			@include set.centeredEnd();
		};

		img {
			height: 83.36px;
			margin: 37px 0 65px;

			@media screen and (max-width: set.$tablet) {
				margin: 15px 0 19px;
			};
			@media screen and (max-width: set.$mobile) {
				margin: 0 0 39px;
			};
		}
	}
}
