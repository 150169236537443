@use '../_settings' as set;

article.services-subpage.postExplain {
	@include set.pageMargin();

	h2 {
		@include set.h1();
		color: black;
		margin: 4.5rem auto;
	}

	dd.segments {
		margin-top: 1rem;

		.segment {

			h2 {
				@include set.h2();
				margin: 3rem 0 4rem;

				@media screen and (max-width: set.$medium) {
					margin: 2rem 0 3rem;
				};
			}

			.textWithImage {
				display: flex;
				justify-content: space-between;

				@media screen and (max-width: set.$medium) {
					flex-direction: column;
				};

				.imageContainer, .textContainer {
					width: 50%;

					@media screen and (max-width: set.$medium) {
						width: 100%;
					};
				}

				.textContainer {
					@media screen and (max-width: set.$tablet) {
						width: 70%;
					};

					@media screen and (max-width: set.$mobile) {
						width: 100%;
					};
				}

				.imageContainer {
					margin-right: 2rem;
					margin-bottom: 2rem;

					img {
						width: 30vw;
						max-width: 53rem;
						object-fit: cover;
						height: 29rem;

						@media screen and (min-width: set.$mobile + 1px) and (max-width: set.$medium) {
							width: 80%;
						};

						@media screen and (max-width: set.$mobile) {
							width: 100%;
							height: 100%;
						};
					}
				}

				.textContainer {

					h4 {
						display: flex;
						align-items: center;
						margin-bottom: 14px;

						img {
							width: 34px;
							height: 34px;
							margin-right: 12px;
						}

						strong {
							@include set.h4();
							// because of font-family
							padding-bottom: 6px;
						}
					}

					p {
						@include set.body();
						margin-bottom: 2rem;
					}
				}
			}
		}

		/* even segments */
		.segment:nth-child(even) {
			@media screen and (min-width: set.$medium + 1px) {
				.textContainer {
					order: 1;
				}

				.imageContainer {
					order: 2;
					margin-right: 0;

					display: flex;
					justify-content: flex-end;
				}
			}
		}

		.segment:last-of-type {
			margin-bottom: 4rem;
		}
	}
}


