@use '../_settings' as set;

.paNo_root {
	@include set.pageMargin();

	.paNo_404Img img {
		margin: 124px auto 43px;
		width: 100%;
		height: auto;

		@media screen and (max-width: set.$tablet){
			width: max(51vw, 400px);
		}

		@media screen and (max-width: set.$mobile){
			width: 72vw;
		}
	}

	.paNo_404Text h2 {
		@include set.h2();
		color: set.$Lightblue;
	}

	.paNo_downArrows img {
		width: 24px;
		margin: 60px auto;

		@media screen and (max-width: set.$mobile){
			margin: 40px auto;
		}
	}

	.paNo_link {
		@media screen and (max-width: set.$tablet){
			margin-bottom: 0;
		}
		@media screen and (max-width: set.$mobile){
			margin-bottom: 35px;
		}

		a {
			color: set.$Grey;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 120px;
			text-decoration: underline;
		}
	}
}
