@use '../_settings' as set;

$customBreakpoint: 800px;

.portfolio > .content {

	.portfolio1 {
		h2 {
			@include set.h1();
		}
	}

	.portfolio2 {
		.servicesTags > div {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 30px 0;

			p {
				padding: 5px 20px 10px;
				background: set.$White;
				box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
				border-radius: 3px;
				margin: 20px;

				a {
					@include set.h4();
					color: set.$Lightblue;
					display: flex;
					text-align: center;
				}
			}
		}
	}

	.portfolio3, .portfolio4, .portfolio5 {
		@include set.servicesListItem();
		//--
		// Comment in!
		//@media screen and (max-width: set.$large) {
		//	background: linear-gradient(transparent, 130px, #F2F1F1, 130px, #F2F1F1);
		//}
		//--

		@media screen and (max-width: $customBreakpoint) {
			margin: 0 0 120px;
		}

		.servicesListItemImg {
			img {
				width: auto;
				height: auto;

				@media screen and (max-width: set.$mobile) {
					width: 100%;
				}
			}
		}

		.servicesListItemText {
			padding: 30px 40px;
			text-align: right;
			height: 100%;

			@media screen and (max-width: set.$large) {
				padding: 0 10px;
				text-align: center;

				p:last-of-type {
					display: flex;
					justify-content: center !important;;
					margin-top: 30px;

					a {
						@include set.buttonLearnMore();
					}

					img {
						margin: 6px 0 30px 10px;
					}
				}
			}

			@media screen and (min-width: set.$large) {

				img {
					margin-bottom: 20px;
				}

				> div p:last-child {
					position: absolute;
					right: 0;
					bottom: 0;
					width: 100%;

					p:last-of-type {
						display: flex;
						justify-content: flex-end;
						margin-top: 6px;

						a {
							@include set.buttonLearnMore();
						}

						img {
							margin: 6px 0 30px 10px;
						}
					}
				}
			}

			h3 {
				@include set.h3();

				strong {
					color: set.$Lightblue;
				}
			}
		}
	}
}
