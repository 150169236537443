@use '_colors' as color;

/* Breakpoints */
$mobile: 600px;
$medium: 768px;
$tablet: 1024px;
$contentWidth: 1200px;
$large: 1350px;

/* Base page margin */
@mixin pageMargin() {
	margin: 0 14%;
	@media screen and (max-width: $tablet) {
		margin: 0 8%;
	};
	@media screen and (max-width: $mobile) {
		margin: 0 4.8%;
	};
}

/* Text base */
@mixin textBase() {
	font-family: 'Catamaran', serif;
	font-style: normal;
}

/* h1 */
@mixin h1() {
	@include textBase();
	font-weight: 800;
	font-size: 74px;
	line-height: 80px;

	@media screen and (max-width: $tablet) {
		font-size: 64px;
		line-height: 78px;
	};
	@media screen and (max-width: $mobile) {
		font-size: 40px;
		line-height: 42px;
	};
}

/* h2 */
@mixin h2() {
	@include textBase();
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;

	@media screen and (max-width: $tablet) {
		font-size: 42px;
		line-height: 52px;
	};
	@media screen and (max-width: $mobile) {
		font-size: 30px;
		line-height: 42px;
	};
}

/* h3 */
@mixin h3() {
	@include textBase();
	font-weight: 800;
	font-size: 34px;
	line-height: 40px;

	@media screen and (max-width: $mobile) {
		font-size: 28px;
		line-height: 34px;
	};
}

/* h4 */
@mixin h4() {
	@include textBase();
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;

	@media screen and (max-width: $mobile) {
		font-size: 20px;
		line-height: 24px;
	};
}

/* Caption */
@mixin caption() {
	@include textBase();
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.02em;
}

/* Body */
@mixin body() {
	@include textBase();
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
}

/* Text link */
@mixin textLink() {
	@include textBase();
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

/* Buttons */
@mixin buttonBase() {
	padding: 10px 32px 14px;
	color: color.$Black;
	border-radius: 3px;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	text-decoration: none;
	letter-spacing: 0.02em;
}

@mixin buttonPrimary() {
	@include buttonBase();
	background-color: color.$Yellow_Btn1;

	&:hover {
		background: linear-gradient(237.39deg, color.$Yellow_Btn1 23.46%, color.$Yellow_Btn1Hover 54.85%, color.$Yellow_Btn1 101.56%);
	}
}

@mixin buttonSecondaryYellow() {
	@include buttonBase();
	height: 46px;
	padding: 5px 32px 9px;
	background-color: color.$White;
	border: 1px solid color.$Yellow_Btn2;

	&:hover {
		background-color: color.$Grey_Btn2Hover;
	}
}

@mixin buttonSecondaryBlue() {
	@include buttonBase();
	border: 1px solid color.$Lightblue;

	&:hover {
		background-color: color.$Grey_Btn2Hover;
	}
}

@mixin buttonLearnMore() {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-decoration-line: underline;
	color: color.$Grey;
}


/* Centered by flexbox */
@mixin centered() {
	display: flex;
	justify-content: center;
	align-items: center;
}

/*  Started and centered by flexbox */
@mixin centeredStart() {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

/*  Ended and centered by flexbox */
@mixin centeredEnd() {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

/* Forced flex display with column direction */
@mixin gridToFlexCol() {
	display: flex !important;
	flex-direction: column;
}

/* Blocked drag */
@mixin userDragNone() {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

/* Services pages */
@mixin servicesListTitle() {
	margin: 0 14%;

	.servicesListTitle h2 {
		@include h2();
		color: color.$Blue;
		margin: 0 0 24px 25.6%;
		text-align: left;

		@media screen and (max-width: $large) {
			margin: 20px 0;
			text-align: center;
		}
	}
}

@mixin servicesListItem() {
	margin: 0 14% 120px;
	min-height: 289px;
	background: linear-gradient(-132.5deg, #F2F1F1, 0%, #F2F1F1 calc(0.7 * 75vw), transparent calc(0.7 * 70vw));
	@media screen and (max-width: $large) {
		background: none;
		display: flex !important;
		flex-direction: column;
	}
	@media screen and (max-width: $mobile) {
		margin: 30px 0;
	}

	.servicesListItemImg {
		@include centered();
		img {
			width: 180px;
			height: 180px;
		}
	}

	.tags {

		@media screen and (max-width: $large) {
			text-align: center;
			margin: 0 10px;
			> div {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
			}
		}

		h3 {
			@include h3();
			margin: 30px 0;

			@media screen and (max-width: $large) {
				width: 100%;
			}

			a {
				text-decoration: none;
				color: color.$Black;
			}
		}

		p {
			@include caption();
			text-align: center;
			background-color: color.$Blue;
			padding: 1.5px 10px 6.5px;
			margin: 8px 16px 8px 0;
			color: color.$Grey_Btn2Hover;
			width: fit-content;
			float: left;

			a {
				text-decoration: none;
				color: color.$Grey_Btn2Hover;
			}
		}
	}

	.servicesListItemText {
		h4 {
			@include h4();
			color: color.$Black;
			max-width: 560px;
			margin: 42px 8% 24px 0;
		}

		p {
			@include textBase();

			@media screen and (max-width: $mobile) {
				text-align: center;
				margin: 30px 0;
			}
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}

		p:last-of-type {
			display: flex;
			justify-content: flex-end;
			margin-top: 6px;

			a {
				@include buttonLearnMore();
			}

			img {
				margin: 6px 8% 23px 10px;
			}
		}

		@media screen and (max-width: $large) {
			width: 100%
		}
	}
}

@mixin servicesSubpageIndexes() {
	article.services-subpage.postIndex {
		@include servicesListTitle();

		@media screen and (max-width: $mobile) {
			@include pageMargin();
		}

		dd.postIndexSegment {
			width: 100%;
			@include servicesListItem();
			margin: 0 0 120px 0;

			@media screen and (min-width: $large + 1px) {
				display: grid;
				grid-template-columns: 26fr 74fr;
			}

			.servicesListItemText {
				h4 {
					@media screen and (max-width: $tablet) {
						text-align: center;
						margin: 30px 15px;
					}
				}

				p {
					@include body;
					color: color.$Grey;
				}
			}
		}
	}
}

/* Expertise section on main page */
@mixin expertiseSection() {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto auto;

	@media screen and (max-width: $tablet) {
		grid-template-columns: auto;
		grid-template-rows: auto auto auto auto;
	}

	section.title, section.icons {
		@include pageMargin();

		@media screen and (max-width: $large) {
			margin: 0 10%;
		}
		@media screen and (min-width: $large + 1px) {
			margin: 0 16%;
		}
	}

	section.title {
		grid-area: 1 / 1 / 2 / 3;

		@media screen and (max-width: $tablet) {
			grid-area: unset;
		}

		h2 {
			@include h2();
			margin: 0 auto 190px;
			color: black;
			text-align: center;

			@media screen and (max-width: $tablet) {
				width: 100%;
				margin: 80px auto 90px;
			}
			@media screen and (max-width: $mobile) {
				margin: 40px auto 65px;
			}
		}
	}

	section.icons {
		grid-area: 2 / 1 / 3 / 3;
		margin-bottom: -26px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media screen and (max-width: $mobile) {
			margin: 40px calc(4.8% + 12px) 65px;
			gap: 24px;
		}
		@media screen and (max-width: $tablet) {
			margin-bottom: 0;
			grid-area: unset;
			justify-content: center;
			gap: 32px;
		}

		picture {
			img {
				width: 80.22px;
				height: 80.22px;
				box-shadow: 17.8px -13.43px 0px color.$Yellow_Btn1;
				background: whitesmoke;
				border-radius: 6px;
				@include userDragNone();

				&:hover {
					cursor: pointer;
					background: color.$White;
				}

				@media screen and (max-width: $tablet) {
					width: 67.72px;
					height: 67.72px;
					box-shadow: 15.2px -11.34px 0px color.$Yellow_Btn1;
					border: 2px solid color.$Grey_Btn2Hover;
					border-radius: 5px;
				}
				@media screen and (max-width: $mobile) {
					width: 45px;
					height: 45px;
					box-shadow: 10px -7.5px 0px color.$Yellow_Btn1;
					border: 2px solid color.$Grey_Btn2Hover;
					border-radius: 5px;
				}
			}
		}

		/* clicked icon buttons */
		.activeIcon img {
			transform: translate(11.2px, -9px);
			box-shadow: 6.6px -4.43px 0 color.$Yellow_Btn1;

			@media screen and (max-width: $tablet) {
				transform: translate(9.45px, -7.6px);
				box-shadow: 5.75px -3.74px 0 color.$Yellow_Btn1;
			}
			@media screen and (max-width: $mobile) {
				transform: translate(6.28px, -5.05px);
				box-shadow: 3.72px -2.45px 0 color.$Yellow_Btn1;
			}
		}
	}

	section.backgroundImg {
		grid-area: 3 / 1 / 4 / 3;
		background: linear-gradient(90deg, color.$LightBlue_2 20%, #7dd5f5 50%, #3991cc 100%);
		user-select: none;

		@media screen and (max-width: $tablet) {
			grid-area: unset;
		}

		img {
			max-width: 1044px;
			width: 75vw;
			height: 459px;
			object-fit: cover;

			@media screen and (max-width: $tablet) {
				width: 100%;
				height: auto;
			}
		}
	}

	section.text {
		display: grid;
		grid-area: 3 / 2 / 4 / 3;
		align-items: center;
		margin: 0 20% 0 42px;

		@media screen and (max-width: $large) {
			margin: 0 20% 0 min(20%, 72px);
		}
		@media screen and (max-width: $tablet) {
			grid-area: unset;
			margin: 0 calc(4.8% + 12px);
		}

		h4 {
			@include h4();

			@media screen and (max-width: $tablet) {
				padding-left: 0;
				margin: 55px auto 120px;
				text-align: center;
			}
			@media screen and (max-width: $mobile) {
				margin: 35px auto 115px;
			}
		}
	}
}

/* SRG circle illustration with texts */
@mixin srgIllustration() {
	position: relative;

	@media screen and (max-width: 768px) {
		min-height: 400px;
		margin-bottom: 80px;
	}
	@media screen and (max-width: $mobile) {
		min-height: 230px;
	}

	img {
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);

		@media screen and (max-width: $tablet) and (min-width: 769px) {
			top: 75px;
			max-width: 250px;
		};
		@media screen and (max-width: $mobile) {
			max-width: 160px;
		};
	}

	h4 {
		@include h4();
		margin: 0;
		color: color.$Grey;

		strong, i, u {
			font-style: normal;
			font-weight: 600;
			position: absolute;
		}

		strong {
			top: 0;
			left: calc(50% - 185px);
			transform: translateX(-50%);
			max-width: 120px;
			text-align: center;

			@media screen and (max-width: $tablet) and (min-width: 769px) {
				left: calc(50% - 100px);
			};
			@media screen and (max-width: $mobile) {
				top: -5px;
				left: calc(50% - 100px);
			};
		}

		i {
			top: 20px;
			left: calc(50% + 165px);
			transform: translateX(-50%);

			@media screen and (max-width: $tablet) and (min-width: 769px) {
				left: calc(50% + 90px);
			};
			@media screen and (max-width: $mobile) {
				left: calc(50% + 100px);
			};
		}

		u {
			top: 340px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;

			@media screen and (max-width: $mobile) {
				top: 200px;
			};
		}
	}
}
