.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: 1em;
	background-color: white;
}

.dialog {
	display: flex;
	flex-direction: column;
	font-size: max(16px, 2vmin);
	width: max(80vmin, 40vw);
	background-color: white;
	box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.25);
	padding: 1.5em;
	border-radius: 1em;

	h1 {
		margin: 1em 0 0.5em 0;
		font-size: 1.4em;
		line-height: 1.2;
	}

	p {
		margin: 0;
	}
}

.illustration {
	height: auto;
	max-height: 10em;
	width: 100%;
	align-self: center;
}
