@use '../_settings' as set;

.services1 {

	.servicesMainImage {
		position: absolute;

		img {
			width: 100vw;
			max-width: 100%;
			height: 550px;
			object-fit: cover;
		}
	}

	.developmentDesignAndMore {
		display: flex;
		align-items: center;
		margin: auto 20%;
		height: 550px;

		@media screen and (max-width: set.$mobile) {
			margin: auto 10px;
		}

		h2 {
			@include set.h1();
			margin-bottom: 50px;
		}

		h4 {
			@include set.h4();
			max-width: 385px;
		}
	}
}

.services2 {
	margin: 50px 0;

	.servicesTitle h2 {
		@include set.h2();
		text-align: center;
	}
}

.services3, .services4, .services5 {
	@include set.servicesListItem();

	//--
	// Comment in!
	//@media screen and (max-width: set.$large) {
	//	background: linear-gradient(transparent, 130px, #F2F1F1, 130px, #F2F1F1);
	//}
	//--

	.servicesListItemText p {
		padding: 20px 20px;
	}
}
