@use '../_settings' as set;

.strategyAndDesignSection{
	margin: 0 14% 105px;
	text-align: center;

	.strategyAndDesign {
		margin: auto;

		> div {
			@media screen and (min-width: set.$tablet) {
				@include set.centered();
			}
		}

		h2 {
			@include set.h1();
			color: black;
			margin: 68px 10px 60px;
		}

		p {
			margin-left: 30px;
		}
	}

	.beforeYouJump p {
		@include set.body();
		color: set.$Blue;
		max-width: 558px;
		min-height: 149px;
		margin: 0;
		text-align: center;
	}
}

/* Indexes of Services subpages */
.servicesSubpages {
	margin-bottom: 182px;
	@include set.servicesSubpageIndexes();
}
