@use '../_settings' as set;

@mixin pageMargin() {
	margin: 0 14%;
	@media screen and (max-width: set.$tablet) {
		margin: 0 8%;
	};
	@media screen and (max-width: set.$mobile) {
		margin: 0 4.8%;
	};
}

.main_theTechnology {
	@include set.centered();
	@include pageMargin();
	padding-top: 140px;

	@media screen and (max-width: set.$mobile) {
		padding-top: 100px;
	}

	h2 {
		@include set.h2();
		margin: 0 auto;
		max-width: 803px;
		min-height: 98px;
		text-align: center;
		color: set.$Black;

	}

	h4 {
		@include set.h4();
		margin: 40px auto 0;
		max-width: 627px;
		min-height: 149px;

		text-align: center;
		color: set.$Grey
	}
}

.main_threeIllustrationsAndTexts {
	@include pageMargin();

	@media screen and (max-width: 1350px) and (min-width: 1025px) {
		margin: 0 10%;
	};
	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
		align-items: center !important;
	};

	.main_threeIllustrations img {
		@media screen and (max-width: set.$tablet) {
			width: 196px;
		};
		@media screen and (max-width: 850px) {
			width: 180px;
		};
		@media screen and (max-width: 768px) {
			width: 242px;
		};
	}

	.main_threeTexts {
		@include set.centered();
		//margin-left: 15%;

		@media screen and (max-width: 768px) {
			margin: 0 0 73px 0;
			text-align: center;
		};

		h3 {
			@include set.h3();
			margin: 18px 0 30px;
			max-width: 250px;
			min-height: 70px;

			@media screen and (max-width: set.$tablet) {
				margin: 36px 0 30px;
				font-size: 30px;
			};
			@media screen and (max-width: 768px) {
				margin: 24px 0;
				max-width: 100%;
				min-height: 30px;
			};
		}

		p {
			@include set.body();
			color: set.$Grey;
			margin: 0;
		}
	}

	.main_moreDetailsBtn {
		@include set.centered();
		margin-top: 74px;

		@media screen and (max-width: 768px) {
			margin-top: 16px;
		};

		a {
			@include set.buttonSecondaryYellow();
		}
	}
}

.trustedBy h2 {
	@include set.h2();
	color: set.$Blue;
	margin: 124px auto 41px;
	text-align: center;
}

.logosSection {
	background-color: set.$Blue;
	padding: 0 5px;

	@media screen and (max-width: set.$mobile) {
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-evenly !important;
		align-items: center !important;
	};

	.logos {
		@include set.centered();
		height: 142px;
		color: white;
		font-size: 24px;

		img {
			@media screen and (max-width: set.$tablet) {
				height: 65px;
			};
			@media screen and (max-width: set.$mobile) {
				height: 45px;
			};
		}
	}
}

.main_anAwesomeSection {
	@include pageMargin();
	position: relative;
	margin-bottom: 120px;

	@media screen and (max-width: set.$tablet) {
		margin-bottom: 180px;
	}
	@media screen and (max-width: set.$mobile) {
		margin-bottom: 110px;
	}

	.main_anAwesome {
		@include set.h2();
		color: set.$Lightblue;
		@include pageMargin();
		margin: 125px auto 100px;
		max-width: 878px;
		text-align: center;

		@media screen and (max-width: set.$tablet) {
			margin: 80px auto 65px;
		};
		@media screen and (max-width: set.$tablet) {
			margin: 70px auto 50px;
		};

		p {
			margin: 0;
		}

		strong {
			color: set.$Black;
		}
	}

	img:not(.main_downArrows img) {
		@include pageMargin();
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);

		@media screen and (max-width: 768px) {
			display: none;
		};
	}

	.main_downArrows img {
		height: 40px;
	}
}

.ourTaskSection, .redesignedWebappSection {
	@media screen and (max-width: 1350px) {
		grid-template-columns: auto calc(100% - 60vw) !important;
	}

	section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

.danubeRiverSection {
	@media screen and (max-width: 1350px) {
		grid-template-columns: calc(100% - 60vw) auto !important;
	}

	section {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}
}

.ourTaskSection, .danubeRiverSection, .redesignedWebappSection {
	grid-template-rows: 532px auto !important;

	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
	}

	/* side sections */
	.main_leftImageSideSection {
		margin-left: 30px;

		@media screen and (max-width: 768px) {
			align-self: start !important;
		}
		@media screen and (max-width: set.$tablet) {
			margin: 0 8% 0 max(6%, 35px);
		}
	}

	.main_rightImageSideSection {
		margin-right: 30px;
		text-align: right;

		@media screen and (max-width: set.$tablet) {
			margin: 0 max(6%, 35px) 0 8%;
		}
	}

	.main_leftImageSideSection, .main_rightImageSideSection {
		@media screen and (max-width: set.$mobile) {
			margin: 0 4.8%;
		}
	}

	section {
		.main_threeImages img {
			width: 885px;
			height: 590px;
			margin-top: -58px;

			@media screen and (max-width: 1350px) {
				width: 59.8vw;
				object-fit: cover;
			}
			@media screen and (max-width: 768px) {
				width: 80vw;
				height: auto;
			}
			@media screen and (max-width: set.$mobile) {
				width: min(100vw, 500px);
			}
		}

		.main_leftImageSideContent, .main_rightImageSideContent {
			max-width: 326px;
			display: flex;
			flex-direction: column;
			min-height: 532px;
			justify-content: space-between;

			/* title */
			h4 {
				@include set.h4();
				color: set.$Black;
				max-width: 260px;
			}

			/* logo */
			img {
				height: 38px;
				margin: 32px 0 24px;
			}

			/* labels */
			strong {
				@include set.caption();
				text-align: center;
				background-color: set.$Blue;
				color: set.$Grey_Btn2Hover;
				display: block;
				padding: 2px 10px 6px;
				border-radius: 2px;
			}

			/* text */
			i {
				clear: both;
				display: block;
				@include set.body();
				color: set.$Grey;
				margin: 0;
				padding: 12px 0 90px;
			}

			a {
				@include set.buttonSecondaryYellow();
				padding: 9px 32px;
				position: absolute;
				bottom: 0;
			}
		}

		.main_leftImageSideContent {
			/* title */
			h4 {
				margin: 0 auto 0 0;

				@media screen and (max-width: 768px) {
					margin-top: 48px;
				}
				@media screen and (max-width: set.$mobile) {
					margin-top: 32px;
				}
			}

			/* labels */
			strong {
				float: left;
				margin: 8px 16px 8px 0;
			}

			a {
				left: 0;
			}
		}

		.main_rightImageSideContent {
			/* title */
			h4 {
				margin: 0 0 0 auto;

				@media screen and (max-width: 768px) {
					margin-top: 48px;
				}
				@media screen and (max-width: set.$mobile) {
					margin-top: 32px;
				}
			}

			/* labels */
			strong {
				float: right;
				margin: 8px 0 8px 16px;
			}

			a {
				right: 0;
			}
		}

		.main_downArrows, .main_circlesAndDownArrows {
			position: relative;
			width: 100%;
			height: 227px;
			margin: 0 auto;

			@media screen and (max-width: 768px) {
				height: 120px;
			}
			@media screen and (max-width: set.$mobile) {
				height: 173px;
			}

			/* Down arrows */
			img:nth-of-type(1) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 40px;

				@media screen and (max-width: 768px) {
					display: none;
				}
			}
		}

		.main_circlesAndDownArrows {

			/* Circles */
			img:nth-of-type(2) {
				position: absolute;
				left: -41px;
				bottom: 0;
				height: 253px;

				@media screen and (max-width: set.$tablet) {
					display: none;
				}
			}
		}
	}
}

.redesignedWebappSection .main_leftImageSideContent {
	img {
		height: 56px !important;
	}
}

.main_howWeMakeSection {
	background: set.$GreyBackground;
	margin-top: 236px;
	padding-bottom: 160px;

	@media screen and (max-width: set.$tablet) {
		margin-top: 80px;
		padding-bottom: 110px;
	};
	@media screen and (max-width: set.$mobile) {
		padding-bottom: 80px;
	};

	.main_howWeMake h2 {
		@include set.h2();
		margin: 120px auto;
		color: black;
		text-align: center;

		@media screen and (max-width: set.$tablet) {
			margin: 80px auto;
		}
		@media screen and (max-width: set.$mobile) {
			margin: 70px auto 40px;
		}
	}

	.main_carousel {
		margin: 0 calc(14% - 50px);
		padding: 0 50px;
		position: relative;

		@media screen and (max-width: set.$tablet) {
			margin: 0 8%;
			padding: 0;
		}
		@media screen and (max-width: set.$mobile) {
			margin: 0 4.8%;
		}

		ol {
			display: flex;
			justify-content: center;

			@media screen and (max-width: set.$tablet) {
				flex-direction: column;
			}

			li {
				width: 33%;
				margin: 0 3%;

				@media screen and (max-width: set.$tablet) {
					width: 100%;
				}
				@media screen and (max-width: set.$mobile) {
					margin: 0;
				}

				dl {
					display: flex;
					flex-wrap: wrap;

					@media screen and (max-width: set.$tablet) {
						margin-bottom: 35px;
					}

					.name, .author , .date, .elapsed_time {
						display: none;
					}

					/* avatar */
					.images {
						width: 76px;
						margin-bottom: 23px;
						order: 1;
					}

					/* name and company */
					.attributes {
						width: calc(100% - 84px);
						margin: 0 0 23px 8px;
						color: set.$Blue;
						align-self: center;
						order: 2;

						/* name */
						.attribute-22 {
							@include set.caption();
							margin-bottom: 4px;
						}

						/* company */
						.attribute-23 {
							font-size: 15px;
						}
					}

					/* text */
					.segments {
						@include set.body();
						color: set.$Grey;
						width: 100%;
						order: 3;
					}
				}
			}
		}

		.nextPageButton, .prevPageButton {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.prevPageButton {
			left: 0;

			@media screen and (max-width: set.$tablet) {
				display: none;
			}
		}

		.nextPageButton {
			right: 0;

			@media screen and (max-width: set.$tablet) {
				top: 100%;
				right: 50%;
				transform: rotate(90deg);
			}
		}
	}
}

.main_whyUsSection {
	@include pageMargin();

	@media screen and (max-width: 1200px) and (min-width: 1025px) {
		margin: 0 8%;
	};
	@media screen and (max-width: 768px) {
		@include set.gridToFlexCol();
	};

	.main_whyUs {
		position: relative;

		h2 {
			@include set.h2();
			height: 55px;
			margin: 120px auto 147px;
			color: black;
			text-align: center;

			@media screen and (max-width: set.$tablet) {
				margin: 80px auto 147px;
			};
			@media screen and (max-width: 768px) {
				margin: 150px auto 85px;
			};
			@media screen and (max-width: set.$mobile) {
				margin: 130px auto 40px;
			};
		}

		img {
			position: absolute;
			top: -180px;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;

			@media screen and (max-width: 768px) {
				top: -370px;
			};
			@media screen and (max-width: set.$mobile) {
				width: 244px;
				top: -270px;
			};
		}
	}

	.main_srgIllustration {
		@include set.srgIllustration();
	}

	.main_weBuild {
		h3 {
			@include set.h3();
			margin: 0 0 40px 0;
			color: set.$Lightblue;

			strong {
				color: set.$Black;
			}
		}

		p:first-of-type {
			@include set.body();
			color: set.$Grey;
			margin: 0;
			padding-bottom: 67px;
		}

		a {
			@include set.buttonSecondaryYellow();

			@media screen and (max-width: 768px) {
				@include set.centered();
				width: 175px;
				height: 57px;
				margin: auto;
			}
		}
	}
}

.main_logosInWhyUsSection {
	margin: 140px 14% 150px;

	@media screen and (max-width: 1350px) {
		margin: 140px 10% 150px;
	};
	@media screen and (max-width: 1200px) {
		margin: 140px 8% 150px;
	};
	@media screen and (max-width: set.$tablet) {
		margin: 85px 6% 80px;
		display: flex !important;
		flex-wrap: wrap;
		justify-content: space-evenly !important;
	};

	.main_logoInWhyUs img, .main_logoInWhyUs_last img {
		margin: 12px;
		max-height: 103px;

		@media screen and (max-width: set.$tablet) {
			max-height: 86px;
		};
	}

	.main_logoInWhyUs img {
		@media screen and (max-width: set.$mobile) {
			margin: 20px;
			max-height: 50px;
		};
	}

	.main_logoInWhyUs_last img {
		@media screen and (max-width: set.$mobile) {
			margin: 20px;
			max-height: 65px;
		};
	}
}

.expertiseSection {
	@include set.expertiseSection();
}

.sevenLogosSection {
	display: flex !important;
	flex-wrap: wrap;
	justify-content: space-evenly !important;
	align-items: center !important;
	margin-bottom: 120px;

	min-height: 200px;
	padding: 0 10%;
	background: #EBEBEB;

	section {
		max-height: 119px;
		margin: 40px;

		picture {
			&.woocommerce {
				img {
					max-height: 90px;

					@media screen and (max-width: set.$tablet) {
						max-height: 80px;
					}
					@media screen and (max-width: set.$mobile) {
						max-height: 50px;
					}
				}
			}

			img {
				max-height: 119px;
				margin: 0 10px;

				@media screen and (max-width: set.$tablet) {
					max-height: 90px;
				}
				@media screen and (max-width: set.$mobile) {
					max-height: 50px;
				}
			}
		}
	}
}
