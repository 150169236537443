@use '../_settings' as set;

$customBreakpoint: 800px;

.career > .content, .karrier > .content {
	max-width: calc(#{set.$contentWidth} / 0.72);
	margin: 0 auto;

	.career1, .career2, .career3, .career4, .career5 {
		@include set.pageMargin();
	}

	.career1 {
		.careerTitle {
			@include set.h1();
			margin: 25px;
		}
	}

	.career2 {
		margin-top: 25px;

		@media screen and (max-width: set.$tablet) {
			display: flex !important;
			flex-direction: column;
		}

		.careerImg {
			margin-left: auto;

			@media screen and (max-width: set.$tablet) {
				margin: 30px auto;

				picture > img {
					width: 100%;
				}
			}
		}

		.careerText {

			@media screen and (min-width: set.$tablet) {
				margin-right: 15px;
			}

			p {
				@include set.body();
			}
		}
	}

	.career3 {

		@media screen and (max-width: set.$tablet) {
			display: none;
		}

		> section {
			margin: 10px 430px 10px auto;
		}
	}

	.career4 {

		.img1 {
			margin-bottom: 15px;

			img {
				object-position: 20% 80%;
			}

			picture > img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		.img2 {
			margin-right: 15px;

			@media screen and (max-width: $customBreakpoint) {
				grid-row-end: 4;
				grid-row-start: 3;
				grid-column-end: 2;
				grid-column-start: 1;
			}

			picture > img {
				max-width: 100%;
				height: 160px;
				object-fit: cover;
			}
		}

		.img3 {
			margin-left: auto;

			@media screen and (max-width: $customBreakpoint) {
				grid-row-end: 4;
				grid-row-start: 3;
				grid-column-end: 3;
				grid-column-start: 2;
			}

			picture > img {
				max-width: 100%;
				height: 160px;
				object-fit: cover;
			}
		}

		.img4 {
			margin-left: auto;
			padding-left: 25px;

			@media screen and (max-width: $customBreakpoint) {
				grid-row-end: 6;
				grid-row-start: 5;
				grid-column-end: 3;
				grid-column-start: 1;
				margin: auto;
				padding: 0;
			}

			picture > img {
				max-height: 500px;

				@media screen and (max-width: set.$tablet) {
					max-height: 340px;
				}
			}
		}

		.weWait {
			margin-left: 25px;
			max-width: 300px;

			@media screen and (max-width: $customBreakpoint) {
				grid-row-end: 5;
				grid-row-start: 4;
				grid-column-end: 3;
				grid-column-start: 1;
				margin: 0;
			}

			@media screen and (max-width: set.$mobile) {
				max-width: 250px;
			}

			p {
				@include set.h2();
				color: set.$Lightblue;
			}
		}
	}

	.career5 {
		margin: 75px 0 50px 0;

		h2 {
			@include set.h2();
		}
	}

	.career6 {
		.root {
			> ul li {
				margin-bottom: 50px;

				article {
					background-color: set.$Grey_Btn2Hover;
					margin: 0 14%;
					padding: 15px 10px;

					@media screen and (max-width: set.$tablet) {
						margin: 0 8%;
					};
					@media screen and (max-width: $customBreakpoint) {
						margin: 0;
						padding: 15px 4.8%;
					};
					@media screen and (max-width: set.$mobile) {
						padding: 32px 4.8% 0;
					};

					> dl {

						.cRoot {
							.cHeader {
								border: none;

								@media screen and (max-width: set.$mobile) {
									flex-direction: column;
								}

								.cIcon {
									order: 1;
									margin-right: 30px;

									span {
										width: 40px;
										height: 40px;

										svg {
											fill: set.$Blue;
										}
									}

									@media screen and (max-width: set.$mobile) {
										order: 2;
										margin: 18px 0 0;
									}
								}

								.cTitle {
									order: 2;
									display: grid;
									grid-template-columns: 1fr 1fr 1fr;
									color: set.$Black;

									@media screen and (max-width: set.$mobile) {
										order: 1;
										grid-template-columns: 1fr 1fr;
									}

									dd {
										display: flex;

										@media screen and (max-width: set.$mobile) {
											align-items: start;
										}

										&:first-child {
											margin-right: 20px;

											@media screen and (max-width: set.$mobile) {
												grid-column: 1/3;
												margin-bottom: 20px;
												justify-content: left;
											}
										}

										&.position {
											@include set.h4();
											align-self: center;

											@media screen and (max-width: set.$mobile) {
												margin-bottom: 20px;
											}
										}

										&.startAt, &.placeOfWork {
											@include set.h4();
											margin-right: 20px;
											flex-direction: column;

											> span:first-child {
												@include set.textLink();
												color: set.$Blue;
											}
										}

										&.startAt {
											> span:first-child {
												&::before {
													content: url("../assets/calendar_mod.png");
													margin-right: 5px;
												}
											}
										}

										&.placeOfWork {
											> span:first-child {
												&::before {
													content: url("../assets/portfolio_mod.png");
													margin-right: 5px;
												}
											}
										}
									}
								}
							}

							.cContent {
								margin-top: 10px;
								padding: 0 55px;

								@media screen and (max-width: set.$mobile) {
									padding: 0 5px;
								}

								.cInnerContent {
									transform: none;
									transition: none;

									dd {
										&.description {

											ul {
												color: set.$Blue;

												li {
													display: flex;
													margin: 10px 0;

													&:before {
														content: url("../assets/star.svg");
														margin-right: 1.3em;
													}
												}
											}

											strong {
												color: set.$Blue;
												line-height: 3;
											}
										}
									}

									.interestedBtn {
										@include set.buttonSecondaryYellow();
										border: 1px solid set.$Lightblue;
										margin: 20px 0;
										padding: 9px 32px;
										width: fit-content;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
